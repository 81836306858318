import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export async function StatusPedidoDetalhe(codigo) {
  const response = await APIGOOPEDIR.get(`api/pedido/status/${codigo}`);

  return response.data;
}

export async function StatusFuncionamento(user) {
  const response = await APIGOOPEDIR.get(`api/empresa/functionamento/${user}`);

  return response.data.status;
}

export async function RetornaEmpresa(empresa) {
  var empresaLogada = process.env.REACT_APP_EMPRESA || empresa;
  var empresaLogada = empresa;
  console.log(empresaLogada);

  try {
    const response = await APIGOOPEDIR.get(`api/empresa/${empresaLogada}`);
    const avaliacao = await APIGOOPEDIR.get(
      `api/empresa/avaliacao/${response.data.user_id}`
    );
    Grava("token", response.data.token);
    Grava("empresa", JSON.stringify(response.data));

    var Empresa = response.data;

    Empresa.avaliacao = {
      media: Number(avaliacao.data[0].media).toFixed(2).replace(",", "."),
      votos: avaliacao.data[0].votos,
    };

    return response.data; // Assumindo que você deseja retornar os dados da resposta
  } catch (error) {
    console.error("Erro ao buscar a empresa:", error);
    return null; // Ou você pode lançar o erro, dependendo de como deseja lidar com ele
  }
}

function agora() {
  return new Date();
}

function diferencaEmMinutos(dataAnterior) {
  const agora = new Date();
  const diferenca = agora - dataAnterior; // Diferença em milissegundos
  return Math.floor(diferenca / (1000 * 60)); // Convertendo milissegundos em minutos
}

function getCategoria(codigo = 0) {
  try {
    const empresaString = localStorage.getItem("empresa");

    if (!empresaString) {
      console.error("Objeto 'empresa' não encontrado no localStorage");
      return null;
    }

    const empresa = JSON.parse(empresaString);

    if (!empresa.item || !Array.isArray(empresa.item)) {
      console.error(
        "Array 'item' não encontrado ou inválido no objeto empresa"
      );
      return null;
    }

    // Filtra apenas a categoria específica se codigo > 0
    const categoriasFiltradas =
      codigo > 0
        ? empresa.item.filter((categoria) => categoria.id === codigo)
        : empresa.item;

    // Mapeia para o formato desejado
    const categorias = categoriasFiltradas.map((categoria) => ({
      id: categoria.id,
      nome_cat: categoria.nome_cat,
      descricao: categoria.desc_cat,
      cor_fundo: categoria.cor_fundo,
      cor_nome: categoria.cor_nome,
      ws_total: categoria.itens.length,
      itens: categoria.itens,
    }));

    // Se foi filtrado por código, retorna o item único ou null se não encontrado
    return codigo > 0 ? categorias[0] || null : categorias;
  } catch (error) {
    console.error("Erro ao processar categorias:", error);
    return null;
  }
}

export async function RetornaCategoria(Usuario) {
  // var Data = JSON.parse(Busca("cat"));
  return await getCategoria(0);
  console.log(await getCategoria());
  var Data = {};

  if (Data != null) {
    if (diferencaEmMinutos(new Date(Data.hora)) < 5) {
      return Data.data;
    }
  } else {
    Data = {};
  }

  try {
    const response = await APIGOOPEDIR.get("api/categoria", {
      headers: {
        Authorization: `Bearer ${Busca("token")}`,
      },
    });

    Data.data = response.data;

    Data.hora = agora();
    Grava("cat", JSON.stringify(Data));

    return response.data; // Assumindo que você deseja retornar os dados da resposta
  } catch (error) {
    console.error("Erro ao buscar a categoria:", error);
    return null; // Ou você pode lançar o erro, dependendo de como deseja lidar com ele
  }
}

// export async function RetornaProduto(Codigo) {

//     var Data = JSON.parse(Busca('product' + Codigo))

//     if (Data != null) {
//         if (diferencaEmMinutos(new Date(Data.hora)) < 5) {
//             return Data.data;
//         }
//     } else {
//         Data = {};
//     }

//     try {
//         const response = await API.get(`produto_categoria.php?codigo=${Codigo}`);
//         Data.data = response.data
//         Data.hora = agora();
//         Grava('product' + Codigo, JSON.stringify(Data));
//         return response.data;  // Assumindo que você deseja retornar os dados da resposta
//     } catch (error) {
//         console.error("Erro ao buscar o produto:", error);
//         return null;  // Ou você pode lançar o erro, dependendo de como deseja lidar com ele
//     }

//     // const { data, loading, error } = useFetch(`categoria.php?codigo=${Usuario}`);

//     // return data;
// }

export async function RetornaProduto(Codigo) {
  let products = {};

  if (
    products[Codigo] &&
    diferencaEmMinutos(new Date(products[Codigo].hora)) < 5
  ) {
    return products[Codigo].data;
  }

  try {
    //const response = await API.get(`produto_categoria.php?codigo=${Codigo}`);
    let response = [];

    const get = await getCategoria(Codigo);

    if (get) {
      response = { dados: get.itens, expira: 999, origem: "localStorage" };
    } else {
      response = await APIGOOPEDIR.get(`api/categoria/produto/${Codigo}`);
      response = response.data;
    }

    products[Codigo] = {
      data: response,
      hora: agora(),
    };
    console.log(response);
    Grava("product", JSON.stringify(products));
    return response; // Assumindo que você deseja retornar os dados da resposta
  } catch (error) {
    console.error("Erro ao buscar o produto:", error);
    return null; // Ou você pode lançar o erro, dependendo de como deseja lidar com ele
  }
}

export function hexToRgba(hex, alpha = 1) {
  let r, g, b;

  // Remova o # na frente, se houver
  hex = hex.replace(/^#/, "");

  // Se for uma string de 3 caracteres, expanda-a para 6 caracteres
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Converte para valores numéricos
  r = parseInt(hex.slice(0, 2), 16);
  g = parseInt(hex.slice(2, 4), 16);
  b = parseInt(hex.slice(4, 6), 16);

  // Retorna a string RGBA
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function lightenHexColor(hex, factor = 0.5) {
  let r, g, b;

  // Remove o # na frente, se houver
  hex = hex.replace(/^#/, "");

  // Se for uma string de 3 caracteres, expanda-a para 6 caracteres
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Converte para valores numéricos
  r = parseInt(hex.slice(0, 2), 16);
  g = parseInt(hex.slice(2, 4), 16);
  b = parseInt(hex.slice(4, 6), 16);

  // Mistura a cor com branco
  r = Math.round(r + (255 - r) * factor);
  g = Math.round(g + (255 - g) * factor);
  b = Math.round(b + (255 - b) * factor);

  return `rgb(${r}, ${g}, ${b})`;
}

export function Url() {
  let url = window.location.href;
  const partsUrl = url.split("/");
  url = partsUrl[0] + "//" + partsUrl[2] + "/" + partsUrl[3];
  url = url.replace("/carrinho", "");
  url = url.replace("/produto", "");
  url = url.replace("/sobre", "");
  console.log(url);
  if (url.slice(-1) === "/") {
    return url.slice(0, -1);
  } else {
    return url;
  }
}

export const API = axios.create({
  baseURL: "https://ws.goopedir.com/v1/",
});

// export const APIGOOPEDIR = axios.create({
//   baseURL: process.env.REACT_BASE_API || "http://localhost:3000/",
//   headers: {
//     Authorization: `Bearer ${Busca("token")}`,
//   },
// });

export const APIGOOPEDIR = axios.create({
  baseURL: process.env.REACT_APP_BASE_API || "http://localhost:3000/",
});

// Interceptor para adicionar o token dinamicamente
APIGOOPEDIR.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const APIPAINEL = axios.create({
  baseURL: "https://ws.goopedir.com/painel/",
  headers: {
    Authorization: `Bearer ${Busca("painel_token")}`,
    User: `${Busca("painel_user")}`,
  },
});

export function PrimeiraLetraMaiuscula(str) {
  try {
    var letra = str;
    if (!letra == "") {
      letra = letra.toLowerCase(letra);
      letra = letra.replace("%20", " ");
      letra = letra.replace("%20", " ");
      letra = letra.replace("%20", " ");
      letra = letra.replace("%20", " ");
      letra = letra.replace("%20", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");
      letra = letra.replace("%", " ");

      return letra
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      return "";
    }
  } catch (error) {}
}

export function ValorProduto(produto) {
  let min = produto.pizza_min;
  let max = produto.pizza_max;

  if (produto.pizza > 0) {
    if (produto.pizza_tipo == 2) {
      min = min * produto.pizza_qtd;
      max = max * produto.pizza_qtd;
    }

    return `De ${FormatoBR(min)} até ${FormatoBR(max)}`;
  } else {
    return FormatoBR(produto.preco_item);
  }
}

export function FormatoBR(valor) {
  if (isNaN(parseFloat(valor)) || !isFinite(valor)) {
    valor = 0;
  }
  const formatoBrasileiro = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatoBrasileiro.format(valor);
}

export function PegaCodigoProdutoUrl() {
  const { product } = useParams();
  let produto = atob(product + "==");
  const parts = produto.split("&&-&&");

  return parts[2];
}

export function Grava(local, value) {
  localStorage.setItem(local, value);
}

export function Busca(local) {
  try {
    return typeof window !== "undefined" ? localStorage.getItem(local) : null;
  } catch (error) {
    console.error("Erro ao acessar localStorage:", error);
    return null;
  }
}

export function Remove(local) {
  localStorage.removeItem(local);
}

export function apenasNumeros(str) {
  return str.replace(/[^0-9]/g, "");
}

export function identificarMeioPagamento(texto) {
  // Convertendo o texto para minúsculas para tornar a comparação case-insensitive
  const textoLowerCase = texto.toLowerCase();

  // Verificando se o texto contém a palavra "pix"
  if (textoLowerCase.includes("pix")) {
    return 1;
  }

  // Verificando se o texto contém algumas palavras relacionadas a cartão, crédito e débito
  if (
    textoLowerCase.includes("cartão") ||
    textoLowerCase.includes("crédito") ||
    textoLowerCase.includes("débito") ||
    textoLowerCase.includes("cartao") ||
    textoLowerCase.includes("credito") ||
    textoLowerCase.includes("debito")
  ) {
    return 2;
  }

  if (textoLowerCase.includes("dinheiro")) {
    return 3;
  }

  // Caso nenhuma das opções seja encontrada
  return 0;
}

export function RetornaIconePagamento(Descricao) {
  if (identificarMeioPagamento(Descricao) == 0) {
    return <i className="fas fa-coins"></i>;
  }
  if (identificarMeioPagamento(Descricao) == 3) {
    return <i class="fas fa-money-bill"></i>;
  }

  if (identificarMeioPagamento(Descricao) == 1) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="16"
        viewBox="0 0 512 512"
      >
        <path d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z" />
      </svg>
    );
  }

  if (identificarMeioPagamento(Descricao) == 2) {
    return <i className="fas fa-credit-card"></i>;
  }
}

export function FocoComponente(nome) {
  const element = document.getElementById(nome);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export function Notificacao(mensagem) {
  toast(mensagem);
}

export function MascaraMonetaria(number) {
  // Converte o número para uma string com duas casas decimais
  let numberString = number.toFixed(2);

  // Substitui o ponto decimal por uma vírgula
  numberString = numberString.replace(".", ",");

  // Adiciona separador de milhares
  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Adiciona o prefixo "R$ "
  return "R$ " + numberString;
}

export function EncodeCodigo(input) {
  let encoded = input;
  encoded = btoa(encoded);
  return encoded;
}

function b64_to_utf8(str) {
  return decodeURIComponent(escape(atob(str)));
}

export function DecodeCodigo(input) {
  let decoded = input;

  decoded = atob(decoded);
  return decoded;
}

export function converterData(dataISO) {
  const data = new Date(dataISO);
  const agora = new Date();

  // Função para zerar horas, minutos, segundos e milissegundos
  function zerarHora(data) {
    data.setUTCHours(0, 0, 0, 0); // Ajusta para UTC
    return data;
  }

  const hoje = zerarHora(new Date(agora));
  const ontem = zerarHora(new Date(agora));
  ontem.setUTCDate(ontem.getUTCDate() - 1);

  const dia = String(data.getUTCDate()).padStart(2, "0");
  const mes = String(data.getUTCMonth() + 1).padStart(2, "0"); // Janeiro é 0
  const ano = data.getUTCFullYear();

  const horas = String(data.getUTCHours()).padStart(2, "0");
  const minutos = String(data.getUTCMinutes()).padStart(2, "0");

  if (zerarHora(new Date(data)) - hoje === 0) {
    return `Hoje às ${horas}:${minutos}`;
  } else if (zerarHora(new Date(data)) - ontem === 0) {
    return `Ontem às ${horas}:${minutos}`;
  } else {
    return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
  }
}
