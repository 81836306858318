import Skeleton from "react-loading-skeleton";
import { ComponentButton } from "../../componentes/buttomBottom";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { ComponenteProdutoPrincipal } from "../../componentes/produtoPrincipal";
import {
  APIGOOPEDIR,
  Notificacao,
  PrimeiraLetraMaiuscula,
  RetornaProduto,
  Url,
} from "../../controller/function";
import React, { useState, useRef, useEffect } from "react";
import { ComponentLoading } from "../../componentes/loading";
import InputMask from "react-input-mask";
import { ValidarCelular, ValidarNome } from "../../utils/validation";
import { Grava, Busca, apenasNumeros } from "../../controller/function";
import { FaClock } from "react-icons/fa";
import "../../styles/timer.css";
import { BuscaOrderRedis, EnviaClienteRedis } from "../../utils/util";
import { useParams } from "react-router-dom";
import Slider from "../../componentes/slide";
import PromoItems from "../../componentes/CardSelecao/PromoItems";

export function PagePrincipal() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);
  const [CategoriaSelecionada, setCategoriaSelecionada] = useState(0);
  const [secaoAtual, setSecaoAtual] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const { empresa } = useParams();
  const [Cart, setCart] = useState([]);

  const [Cliente, setCliente] = useState({
    celular: "",
    nome: "",
    cpf: "",
    nascimento: "",
    rua: "",
    ruaa: "",
    bairro: "",
    cidade: "",
    uf: "",
    complemento: "",
    numero: "",
    lat: 0,
    lng: 0,
  });

  const refs = useRef([]);

  useEffect(() => {
    if (
      CategoriaSelecionada !== null &&
      refs.current[CategoriaSelecionada] &&
      refs.current[CategoriaSelecionada].current
    ) {
      const element = refs.current[CategoriaSelecionada].current;
      const offset = 80; // Ajuste conforme necessário

      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - offset,
        behavior: "smooth",
      });
    }
  }, [CategoriaSelecionada]);

  const handleScroll = () => {
    let indexAtual = null;

    Categoria.forEach((_, index) => {
      const refCurrent = refs.current[index].current;
      if (refCurrent) {
        const top = refCurrent.getBoundingClientRect().top;
        if (
          top <= window.innerHeight * 0.2 &&
          top >= -window.innerHeight * 0.8
        ) {
          indexAtual = index;
        }
      }
    });

    if (indexAtual !== null && indexAtual !== secaoAtual) {
      setSecaoAtual(indexAtual);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [Categoria, secaoAtual]);

  const handleSave = () => {
    if (!ValidarCelular(Cliente.celular)) {
      Notificacao("Por favor, insira um telefone válido");
      return;
    }

    if (!ValidarNome(Cliente.nome)) {
      Notificacao("Por favor, insira um nome válido");
      return;
    }
    console.log(Cliente.celular);
    fetchOrder(Cliente.celular);

    // Save to localStorage

    Grava("client", JSON.stringify(Cliente));

    setShowModal(false);
  };

  async function fetchOrder(celular) {
    let value = await BuscaOrderRedis(empresa, celular);
    setCart(value);
    Grava("cart", JSON.stringify(value));

    // Dispara o evento para atualizar o ComponentButton
    window.dispatchEvent(new Event("cartUpdate"));
    Notificacao("Seu ultimo carrinho foi recuperado!");
  }

  function SetDadosCliente(field, valor) {
    setCliente((prevCliente) => {
      let client = { ...prevCliente };
      client[field] = PrimeiraLetraMaiuscula(valor);
      return client;
    });
  }

  function SetaCelular(celular) {
    SetDadosCliente("celular", apenasNumeros(celular));
    if (celular.length == 15) {
      let client = Cliente;
      client.celular = apenasNumeros(celular);

      // "generica.php?tabela=ws_pedidos&where=telefone = '" +
      // apenasNumeros(celular) +
      // "' and user_id = '" +
      // Empresa.user_id +
      // "' and opcao_delivery <> 'false' order by ws_pedidos.id desc limit 1"

      APIGOOPEDIR.get(
        `api/dados/cliente/${client.celular}/${Empresa.user_id}`
      ).then(function (response) {
        const data = response.data;
        // Verifique se os dados são válidos e são um array
        if (Array.isArray(data) && data.length) {
          const clientData = data[0];
          let client = Cliente;

          // Atributos que desejamos definir no cliente
          const attributes = [
            "nome",
            "cpf",
            "nascimento",
            "rua",
            "ruaa",
            "bairro",
            "cidade",
            "uf",
            "complemento",
            "numero",
            "lat",
            "lng",
          ];

          attributes.forEach((attr) => {
            if (clientData[attr]) {
              client[attr] = PrimeiraLetraMaiuscula(clientData[attr]);
            }
          });

          Grava("client", JSON.stringify(client));
          EnviaClienteRedis(empresa);

          setCliente(client);
        }
      });
    }
  }

  useEffect(() => {
    // Check if client data exists in localStorage
    const clientData = JSON.parse(Busca("client"));
    if (clientData) {
      setShowModal(false);
    } else {
      // startTimer();
    }
  }, []);

  const startTimer = () => {
    const startTime = new Date().getTime();
    localStorage.setItem("timerStartTime", startTime.toString());
    setTimeLeft(900); // 15 minutes in seconds
  };

  useEffect(() => {
    // Check if there's a saved timer
    const savedStartTime = localStorage.getItem("timerStartTime");

    if (savedStartTime) {
      const startTime = parseInt(savedStartTime);
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      const remainingTime = Math.max(900 - elapsedSeconds, 0); // 900 seconds = 15 minutes

      setTimeLeft(remainingTime);
    }
  }, []);

  useEffect(() => {
    if (timeLeft === null || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = prev - 1;
        if (newTime <= 0) {
          // Timer finished - you can add any completion logic here
          localStorage.removeItem("timerStartTime");
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const slides = [
    {
      image: "https://example.com/banner1.jpg",
      content: <h2>Promoção 1</h2>,
    },
    {
      image: "https://example.com/banner2.jpg",
      content: <h2>Promoção 2</h2>,
    },
    {
      image: "https://example.com/banner3.jpg",
    },
  ];

  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="card">
              <h4 className="mb-0">Informe seus dados</h4>
              <h6 className="mb-4">Necessário para continuar.</h6>

              <div className="mb-3">
                <label className="form-label">Telefone</label>
                <span className="badge ms-2">Obrigatório</span>
                <InputMask
                  mask="(99) 99999-9999"
                  maskChar={null}
                  className="form-control"
                  placeholder="(00) 00000-0000"
                  onChange={(e) => SetaCelular(e.target.value)}
                  value={Cliente.celular}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Nome</label>
                <span className="badge ms-2">Obrigatório</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Seu nome e sobrenome"
                  value={Cliente.nome}
                  onChange={(e) => SetDadosCliente("nome", e.target.value)}
                />
              </div>

              <button className="btn btn-yellow w-100" onClick={handleSave}>
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}

      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={""}
      />
      {Empresa.length !== 0 ? (
        <>
          {timeLeft !== null && (
            <div className="timer-container">
              <FaClock className="timer-icon" />
              <p className="timer-text">
                Tempo restante para finalizar seu pedido:
                <span className="timer-count">{formatTime(timeLeft)}</span>
              </p>
            </div>
          )}

          <section className="categoria width-fix mt-4">
            <div className="container-menu" id="listaCategorias">
              {Categoria.map((categoria, index) => (
                <p
                  key={index}
                  className={
                    CategoriaSelecionada == index
                      ? "item-categoria btn btn-white-select btn-sm mb-3 me-3"
                      : "item-categoria btn btn-white btn-sm mb-3 me-3"
                  }
                  onClick={() => setCategoriaSelecionada(index)}
                >
                  {PrimeiraLetraMaiuscula(categoria.nome_cat)}
                </p>
              ))}
            </div>
          </section>

          <section
            className="lista width-fix mt-0 pb-5"
            id="listaItensCardapio"
          >
            {Categoria.forEach((_, i) => {
              if (!refs.current[i]) refs.current[i] = React.createRef();
            })}
            {Categoria.map((categoria, index) => (
              <PromoItems
                headerBackgroundImage="" // Opcional
                headerBackgroundColor={categoria.cor_fundo} // Opcional (usado se não houver imagem)
                headerTextColor={categoria.cor_nome} // Opcional
                headerHeight="60px" // Opcional
                headerDescription={PrimeiraLetraMaiuscula(categoria.nome_cat)}
                ref={refs.current[index]}
                categoria={categoria.id}
                empresa={Empresa}
                items={categoria.itens}
              />
            ))}
          </section>

          <ComponentButton />
        </>
      ) : (
        <>
          <ComponentLoading />
        </>
      )}
    </>
  );
}
