import { Busca, Url } from "../../controller/function";
import React, { useState, useRef, useEffect } from "react";

export function ComponentButton() {
  const [Carrinho, setCarrinho] = useState([]);

  useEffect(() => {
    // Função para atualizar o carrinho
    const updateCart = () => {
      let Cart = JSON.parse(Busca("cart"));
      if (!Cart) {
        Cart = [];
      }
      setCarrinho(Cart);
    };

    // Adiciona o listener do evento
    window.addEventListener("cartUpdate", updateCart);

    // Executa a primeira vez
    updateCart();

    // Cleanup
    return () => {
      window.removeEventListener("cartUpdate", updateCart);
    };
  }, []);

  function Active(rota) {
    return window.location.href == `${Url()}${rota}` ? "active" : "";
  }

  return (
    <>
      <section className="menu-bottom" id="menu-bottom">
        <a href={`${Url()}`} className={`menu-bottom-item ${Active("")}`}>
          <i className="fas fa-book-open"></i>&nbsp; Cardápio
        </a>
        <a
          href={`${Url()}/pedido`}
          className={`menu-bottom-item ${Active("/pedido")}`}
        >
          <i className="fas fa-utensils"></i>&nbsp; Pedido
        </a>
        <a
          href={`${Url()}/carrinho`}
          className={`menu-bottom-item ${Active("/carrinho")}`}
        >
          {Carrinho.length > 0 ? (
            <span className="badge-total-carrinho">{Carrinho.length}</span>
          ) : null}
          {/* <i className="fas fa-shopping-cart"></i> */}
          Carrinho
        </a>
      </section>

      <section className="menu-bottom disabled hidden" id="menu-bottom-closed">
        <p className="mb-0">
          <b>Loja fechada no momento.</b>
        </p>
      </section>
    </>
  );
}
