import { useState, useEffect } from "react";
import {
  Grava,
  PrimeiraLetraMaiuscula,
  RetornaCategoria,
  RetornaEmpresa,
  Url,
  hexToRgba,
  lightenHexColor,
} from "../../controller/function";
import { useParams } from "react-router-dom";
import StarRating from "./star";
import { Cabecalho } from "./cabecalho";
import { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer } from "react-toastify";

export function ComponenteEmpresa({ setempresa, setcategoria, display }) {
  const [Empresa, setEmpresa] = useState([]);
  const { empresa } = useParams();

  // function setFavicon(src) {
  //   let link =
  //     document.querySelector("link[rel*='icon']") ||
  //     document.createElement("link");
  //   link.type = "image/x-icon";
  //   link.rel = "shortcut icon";
  //   link.href = src;
  //   document.getElementsByTagName("head")[0].appendChild(link);
  // }

  const setMetaTag = (name, content) => {
    let element = document.querySelector(`meta[name='${name}']`);
    if (element) {
      element.setAttribute("content", content);
    } else {
      element = document.createElement("meta");
      element.setAttribute("name", name);
      element.setAttribute("content", content);
      document.head.appendChild(element);
    }
  };

  const setFavicon = (href) => {
    let link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = href;
    } else {
      link = document.createElement("link");
      link.rel = "icon";
      link.href = href;
      document.head.appendChild(link);
    }
  };

  const setIcon = (href) => {
    let link = document.querySelector("link[rel='apple-touch-icon']");
    if (link) {
      link.href = href;
    } else {
      link = document.createElement("link");
      link.rel = "apple-touch-icon";
      link.href = href;
      document.head.appendChild(link);
    }
  };

  useEffect(() => {
    async function fetchEmpresa() {
      let value = await RetornaEmpresa(empresa);

      Grava(
        "icon",
        `https://fotos.goopedir.com/fotos/empresa/${btoa(value.user_id)}`
      );
      Grava("name", value.nome_empresa);

      if (value != null) {
        const root = document.documentElement;
        root.style.setProperty("--color-primary", value.cor_topo);
        root.style.setProperty(
          "--color-secondary",
          lightenHexColor(value.cor_topo, 0.3)
        );
        root.style.setProperty(
          "--color-primary-out",
          lightenHexColor(value.cor_topo, 0.3)
        );

        root.style.setProperty("--rmsc-border", value.cor_titulo_produtos);
        root.style.setProperty("--color-text-out", value.cor_titulo_produtos);
        root.style.setProperty(
          "--rmsc-main",
          lightenHexColor(value.cor_topo, 0.3)
        );

        document.title = value.nome_empresa;
        setFavicon(
          `https://fotos.goopedir.com/fotos/empresa/${btoa(value.user_id)}`
        );
        setIcon(
          `https://fotos.goopedir.com/fotos/empresa/${btoa(value.user_id)}`
        );
        setMetaTag("description", value.nome_empresa);

        try {
          const elementoRmsc = document.querySelector(".rmsc");
          elementoRmsc.style.setProperty(
            "--rmsc-main",
            value.cor_topo // Substitua pelo valor desejado
          );
        } catch (e) {}

        let categoria = await RetornaCategoria(value.user_id);

        if (categoria == null) {
          categoria = await RetornaCategoria(value.user_id);
        }

        setcategoria(categoria);
        console.log(categoria);

        setEmpresa(value);
        setempresa(value);
      }
    }
    fetchEmpresa();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        // theme="dark"
        pauseOnHover
      />
      <SkeletonTheme baseColor="#202020" highlightColor="#444"></SkeletonTheme>
      {display != "none" ? (
        <>
          {Empresa.length !== 0 ? (
            <>
              <Cabecalho Empresa={Empresa} />
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
