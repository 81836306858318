import { useEffect, useState } from "react";
import {
  PrimeiraLetraMaiuscula,
  StatusFuncionamento,
  Url,
} from "../../../controller/function";
import StarRating from "../star";

export function Cabecalho({ Empresa }) {
  const [Status, setStatus] = useState(Empresa.status);

  // console.log(Empresa.user_id);

  useEffect(() => {
    // Função para verificar o status da loja
    const checkStoreStatus = async () => {
      let value = await StatusFuncionamento(Empresa.user_id);
      setStatus(value);
    };

    // Verifica o status da loja imediatamente
    checkStoreStatus();

    // Define um intervalo para verificar o status da loja a cada 15 segundos
    const intervalId = setInterval(checkStoreStatus, 15000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="bg-top"></div>
      <header className="width-fix mt-5">
        <div className="card">
          <div className="d-flex">
            <div
              className="container-img"
              style={{
                backgroundImage: `url("https://fotos.goopedir.com/fotos/empresa/${btoa(
                  Empresa.user_id
                )}")`,
              }}
            ></div>
            <div className="infos">
              <h1>
                <b>{PrimeiraLetraMaiuscula(Empresa.nome_empresa)}</b>
              </h1>
              <StarRating
                nota={
                  Empresa.avaliacao?.media > 0 ? Empresa.avaliacao?.media : 5
                }
                avaliacoes={
                  Empresa.avaliacao?.votos > 0 ? Empresa.avaliacao?.votos : 0
                }
              />
              <div className="infos-sub">
                {Status ? (
                  <>
                    <p className="status-open">
                      <i className="fa fa-clock"></i> Aberta
                    </p>
                  </>
                ) : (
                  <>
                    <p className="status-closed">
                      <i className="fa fa-clock"></i> Fechada
                    </p>
                  </>
                )}

                <a href={Url() + "/sobre"} className="link">
                  ver mais
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {Empresa.fidelidade_status == 1 ? (
        <header className="width-fix mt-2">
          <div className="card">
            <div className="d-flex">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                {" "}
                <div
                  className="container-img"
                  style={{
                    backgroundImage: `url("/gift.png")`,
                  }}
                ></div>
              </div>
              {/* //Empresa.fidelidade_status */}

              <div className="infos">
                <h1 style={{ fontSize: "0.85rem" }}>
                  <b>Programa De Fidelidade</b>
                </h1>
                <p style={{ fontSize: "0.75rem" }}>
                  A cada R$ 1,00 em compras você ganha 1 ponto que pode ser
                  trocado por prêmios e descontos exclusivos.
                </p>
                <a
                  href={Url() + "/fidelidade"}
                  className="link"
                  style={{ fontSize: "0.75rem", marginTop: "-15px" }}
                >
                  Clique aqui e leia o regulamento
                </a>
              </div>
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
}
