import React, { useState } from "react";
import styled from "styled-components";
import {
  PrimeiraLetraMaiuscula,
  RetornaProduto,
  Url,
  ValorProduto,
} from "../../controller/function";

const PromoContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 20px;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center/cover`
      : props.backgroundColor || "var(--color-primary)"};
  color: ${(props) => props.textColor || "white"};
  border-radius: 12px 12px 0 0;
  height: ${(props) => props.height || "120px"};
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

// Container para layout horizontal (destaque)
const ItemsContainerHorizontal = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 16px;
  gap: 20px;
  background: white;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darkred;
  }
`;

// Container para layout vertical (categoria normal)
const ItemsContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
  background: white;
`;

const ItemHorizontal = styled.div`
  min-width: 170px;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ItemVertical = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

const ItemImageHorizontal = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ItemImageVertical = styled.div`
  position: relative;
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AddButton = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background: var(--color-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const PriceContainer = styled.div`
  width: 100%;
  margin-bottom: 4px;
`;

const OriginalPrice = styled.div`
  text-decoration: line-through;
  color: #666;
  font-size: 1rem;
`;

const PromoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || "center"};
  gap: 8px;
  margin-top: 4px;
`;

const PromoPrice = styled.span`
  color: #28a745;
  font-size: ${(props) => props.size || "1.2rem"};
  font-weight: bold;
`;

const DiscountBadge = styled.span`
  background: #28a745;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.7rem;
`;

const NormalPrice = styled.div`
  font-size: ${(props) => props.size || "1.2rem"};
  font-weight: bold;
`;

const ProductName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
  width: 100%;
  word-break: break-word;
  text-align: ${(props) => props.align || "center"};
  font-size: ${(props) => props.size || "1rem"};
`;

const ProductDescription = styled.div`
  color: #666;
  font-size: ${(props) => props.size || "0.8rem"};
  width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  text-align: ${(props) => props.align || "center"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

const ProductInfoVertical = styled.div`
  flex: 1;
`;

const PromoItems = React.forwardRef(
  (
    {
      headerBackgroundImage,
      headerBackgroundColor,
      headerTextColor,
      headerHeight,
      headerDescription,
      items,
      empresa,
      isDestaque = false,
      // Não inclua ref aqui - ela virá do forwardRef
    },
    ref
  ) => {
    const idProduto =
      empresa.user_id + "&&-&&" + empresa.nome_empresa + "&&-&&";

    function Crip(valor) {
      valor = btoa(valor);
      return valor.slice(0, -2);
    }

    function AbrirProduto(codigo) {
      window.location.href = Url() + "/produto/" + codigo;
    }

    return (
      <PromoContainer>
        <Header
          backgroundImage={headerBackgroundImage}
          backgroundColor={headerBackgroundColor}
          textColor={headerTextColor}
          height={headerHeight}
          ref={ref}
        >
          {headerDescription}
        </Header>

        {isDestaque ? (
          <ItemsContainerHorizontal>
            {items.map((item, index) => (
              <ItemHorizontal
                key={index}
                onClick={() =>
                  AbrirProduto(
                    Crip(idProduto + item.id + "&&-&&" + item.nome_item)
                  )
                }
              >
                <ItemImageHorizontal>
                  <img
                    src={
                      item.img_ifood != ""
                        ? `${item.img_ifood}`
                        : `https://fotos.goopedir.com/fotos/${btoa(item.id)}`
                    }
                    alt={item.nome_item}
                  />
                  <AddButton>+</AddButton>
                </ItemImageHorizontal>

                <PriceContainer>
                  {item.promo_valor ? (
                    <>
                      <OriginalPrice>
                        R${item.preco_item.toFixed(2)}
                      </OriginalPrice>
                      <PromoPriceContainer>
                        <PromoPrice>R${item.promo_valor.toFixed(2)}</PromoPrice>
                        <DiscountBadge>
                          {Math.round(
                            (1 - item.promo_valor / item.preco_item) * 100
                          )}
                          %
                        </DiscountBadge>
                      </PromoPriceContainer>
                    </>
                  ) : (
                    <NormalPrice>
                      {item.preco_item > 0 ? `${ValorProduto(item)}` : ``}
                    </NormalPrice>
                  )}
                </PriceContainer>

                <ProductName>
                  {PrimeiraLetraMaiuscula(item.nome_item)}
                </ProductName>
                <ProductDescription>
                  {PrimeiraLetraMaiuscula(item.descricao_item)}
                </ProductDescription>
              </ItemHorizontal>
            ))}
          </ItemsContainerHorizontal>
        ) : (
          <ItemsContainerVertical>
            {items.map((item, index) => (
              <ItemVertical
                key={index}
                onClick={() =>
                  AbrirProduto(
                    Crip(idProduto + item.id + "&&-&&" + item.nome_item)
                  )
                }
              >
                <ItemImageVertical>
                  <img
                    src={
                      item.img_ifood != ""
                        ? `${item.img_ifood}`
                        : `https://fotos.goopedir.com/fotos/${btoa(item.id)}`
                    }
                    alt={item.nome_item}
                  />
                  <AddButton>+</AddButton>
                </ItemImageVertical>

                <ProductInfoVertical>
                  <ProductName align="left" size="1.1rem">
                    {PrimeiraLetraMaiuscula(item.nome_item)}
                  </ProductName>
                  <ProductDescription align="left" marginBottom="8px">
                    {PrimeiraLetraMaiuscula(item.descricao_item)}
                  </ProductDescription>

                  {item.promo_valor ? (
                    <>
                      <PromoPriceContainer justify="flex-start">
                        <PromoPrice size="1.1rem">
                          R${item.promo_valor.toFixed(2)}
                        </PromoPrice>
                        <DiscountBadge>
                          {Math.round(
                            (1 - item.promo_valor / item.preco_item) * 100
                          )}
                          %
                        </DiscountBadge>
                      </PromoPriceContainer>
                      <OriginalPrice>
                        R${item.preco_item.toFixed(2)}
                      </OriginalPrice>
                    </>
                  ) : (
                    <NormalPrice size="1.1rem">
                      {item.preco_item > 0 ? `${ValorProduto(item)}` : ``}
                    </NormalPrice>
                  )}
                </ProductInfoVertical>
              </ItemVertical>
            ))}
          </ItemsContainerVertical>
        )}
      </PromoContainer>
    );
  }
);

export default PromoItems;
