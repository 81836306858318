import React from "react";
import "./StarRating.css"; // Certifique-se de criar um arquivo CSS para estilizar o componente

const StarRating = ({ nota, avaliacoes }) => {
  // Arredondar a nota para o número inteiro mais próximo no intervalo de 1 a 5
  const roundedNota = Math.round(Math.min(Math.max(nota, 1), 5));

  // Calcular a parte decimal da nota para pintar a metade da estrela
  const decimalPart = nota - Math.floor(nota);

  // Criar um array com 5 elementos representando as estrelas
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span
      key={index}
      className={`star ${index + 1 <= roundedNota ? "filled" : ""}`}
      style={{
        clipPath:
          index + 1 === roundedNota && decimalPart > 0
            ? `polygon(0% 0%, ${decimalPart * 50}% 0%, ${
                decimalPart * 50
              }% 100%, 0% 100%)`
            : "none",
      }}
    >
      &#9733; {/* Caractere da estrela */}
    </span>
  ));

  return (
    <div>
      <div style={{ display: "flex", marginTop: "-6px" }}>
        <div className="star-rating">{stars}</div>
        <div
          style={{ marginTop: "10px", marginLeft: "4px", fontSize: "0.8rem" }}
        >
          <p>({nota})</p>
        </div>
      </div>
      <div style={{ fontSize: "0.70rem", marginTop: "-18px" }}>
        {avaliacoes > 0 ? <p>{avaliacoes} Avaliações</p> : null}
      </div>
    </div>
  );
};

export default StarRating;
